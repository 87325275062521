<template>
  <div class="form-group">
    <label class="form-label">Filter by sign up origin: </label>
    <select class="form-control form-control-sm" v-model="selectedSignUpOriginFilter" @change="selected">
        <option value="all">All</option>
        <option v-for="(option, index) in signupOriginOptions" v-bind:key="index" :value="option">{{option}}</option>
    </select>
  </div>
</template>

<script>
export default {
    props: ['signupOriginOptions'],

    data() {
        return {
            selectedSignUpOriginFilter: ''
        };
    },

    methods: {
        selected($event) {
            this.$emit("handleUpdateUserOriginFilter", $event.target.value);
        }
    },

    created() {
        // Set options based off props bro
    }
};
</script>
