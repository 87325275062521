<template>
  <div class="users">
    <div class="modal fade" id="deleteRecordModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Confirm you would like to delete this user!
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure you would like to delete this user?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="saveDeleteRecord"
            >
              Yes
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="cancelRecordModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Confirm you would like to cancel this user!
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure you would like to cancel this user?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="saveCancelRecord"
            >
              Yes
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2">Users</h1>
      <strong v-if="counts">
        Active: {{ counts.active }}, Pending: {{ counts.pending }},
        Inactive: {{ counts.inactive }}, Total: {{ counts.total }}
        <br />
        Stripe Monthly: {{ counts.stripe_monthly }}, Stripe Annual:
        {{ counts.stripe_annual }}
        <br />
        Apple Monthly: {{ counts.apple_monthly }}, Apple Annual:
        {{ counts.apple_annual }}
        <br />
        Apple Auto Renew Off: {{ counts.active_auto_renew_off }}, Apple Auto
        Renew On: {{ counts.active_auto_renew_on }}
      </strong>
      <div>
        <input
          class="form-control form-control-sm w-100"
          type="text"
          placeholder="Search"
          aria-label="Search"
          v-model="search"
        />
      </div>
    </div>

    <div class="btn-group" role="group">
      <button
        id="btnGroupDrop1"
        type="button"
        class="btn btn-secondary dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Download for Analytics
      </button>
      <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
        <a href="#" @click="download('active')" class="dropdown-item">Active</a>
        <a href="#" @click="download('disabled')" class="dropdown-item"
          >Disabled</a
        >
        <a href="#" @click="download('pending')" class="dropdown-item"
          >Pending</a
        >
        <a href="#" @click="download('all')" class="dropdown-item">All</a>
      </div>
    </div>

    <div class="mt-3 mb-3 col-3">
      <filterSignupOrigin :signupOriginOptions="signupOriginList" @handleUpdateUserOriginFilter="filterCustomersByOrigin" />
    </div>

    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Full Name</th>
          <th scope="col">Email</th>
          <th scope="col">Plan</th>
          <th scope="col">Enabled</th>
          <th scope="col">Auto Renew</th>
          <th scope="col">Payment System ID</th>
          <th scope="col">Last Login Platform</th>
          <th scope="col">Subscribed On</th>
          <th scope="col">Renewal Date</th>
          <th scope="col">Cancelled On</th>
          <th scope="col">Created On</th>
          <th scope="col">&nbsp;</th>
        </tr>
      </thead>
      <tbody v-if="this.customers.length > 0">
        <tr v-for="(customer, index) in filteredCustomers" v-bind:key="customer.id" @click="selectCustomerRow($event, customer)">
          <th scope="row">{{ customer.id }}</th>
          <td>{{ customer.fullname }}</td>
          <td>{{ customer.email }}</td>
          <td>{{ customer.plan }}</td>
          <td>{{ customer.enabled }}</td>
          <td>{{ customer.apple_auto_renew_status || customer.google_auto_renewing }}</td>
          <td>{{ customer.payment_system }}<br>{{ customer.stripe_customer_id }}<br>{{ customer.original_transaction_id }}</td>
          <td>{{ customer.last_login_platform }}</td>
          <td>{{ customer.subscribed_on | dateformat }}</td>
          <td>{{ customer.renewal_date | dateformat }}</td>
          <td>{{ customer.cancelled_on | dateformat }}</td>
          <td>{{ customer.created_on | dateformat }}</td>

          <td>
            <div class="btn-group" role="group">
              <button
                id="btnGroupDrop1"
                type="button"
                class="btn btn-secondary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Action
              </button>
              <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                <a
                  class="dropdown-item"
                  :href="'/users/password/' + customer.id"
                  >Change Password</a
                >
                <a
                  class="dropdown-item"
                  :href="'/users/profile/' + customer.id"
                  >Edit</a
                >
                <button
                  class="dropdown-item"
                  v-if="customer.payment_system === 'stripe'"
                  @click="confirmCancelRecord($event, customer.id, index)"
                  >Cancel</button
                >
                <!-- <button type="button" class="btn btn-danger" @click="confirmDeleteRecord($event, user.id, index)">Delete</button> -->
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import $ from "jquery";
import _ from "lodash";
import CustomerSignUpOriginFilter from "../components/stats/CustomerSignUpOriginFilter";

export default {
  components: {
    filterSignupOrigin: CustomerSignUpOriginFilter,
  },
  data: function() {
    return {
      customers: [],
      signupOriginList: [],
      search: "",
      counts: {
        total: 0,
        inactive: 0,
        active: 0,
        pending: 0,
        total: 0,
        stripe_annual: 0,
        stripe_monthly: 0,
        apple_monthly: 0,
        apple_annual: 0,
        active_auto_renew_on: 0,
        active_auto_renew_off: 0
      },
      deleteRecord: {
        index: 0,
        id: 0
      },
      cancelUser: {
        index: 0,
        id: 0
      }
    };
  },

  methods: {
    saveDeleteRecord: function() {
      this.$http
        .delete(process.env.VUE_APP_ROOT_API + "/customers/" + this.deleteRecord.id)
        .then(response => {
          console.log(response);
          //$('#deleteRecordModal').modal('hide');
          document.querySelectorAll("[data-dismiss=modal]")[0].click();
          this.customers.splice($this.deleteRecord.index, 1);
        });
    },

    selectCustomerRow: function(e, customer) {
      if (e.target.tagName === 'BUTTON') {
        e.preventDefault();
      } else {
        this.$router.push("/users/profile/" + customer.id);
      }
    },

    download(filter) {
      let token = this.$cookies.get("poolfit_admin_session");

      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token }
        };
        this.$http
          .get(
            process.env.VUE_APP_ROOT_API +
              "/analytics/users/download?filter=" +
              filter,
            options
          )
          .then(response => {
            const blob = new Blob([response.data], { type: "text/csv" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "latest.csv";
            link.click();
            URL.revokeObjectURL(link.href);
          });
      }
    },

    saveCancelRecord: function() {
      let token = this.$cookies.get("poolfit_admin_session");

      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token }
        };

        this.$http
          .delete(
            process.env.VUE_APP_ROOT_API + "/stripe/cancel/" + this.cancelUser.id,
            options
          )
          .then(response => {
            console.log(response);
            $('#cancelRecordModal').modal('hide');
            document.querySelectorAll("[data-dismiss=modal]")[0].click();
            this.customers[this.cancelUser.index].enabled = 0;
            this.customers.splice(this.deleteRecord.index, 1);
          });
      }
    },

    confirmDeleteRecord: function(event, id, index) {
      this.deleteRecord = {
        id: id,
        index: index
      };

      $("#deleteRecordModal").modal({});
    },

    confirmCancelRecord: function(event, id, index) {
      event.preventDefault();

      this.cancelUser = {
        id: id,
        index: index
      };

      $("#cancelRecordModal").modal({});

      return false;
    },

    filterCustomers: function(filter) {
      let token = this.$cookies.get("poolfit_admin_session");

      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token }
        };
        this.$http
          .get(
            process.env.VUE_APP_ROOT_API + "/customers?filter=" + filter,
            options
          )
          .then(response => {
            this.search = "";
            this.customers = response.data.customers;
            this.counts = _.head(response.data.meta);
            this.signupOriginList = response.data.customers.map(cust => cust.sign_up_platform).filter((value, index, array) => array.indexOf(value) === index);
          });
      }
    },

    filterCustomersByOrigin: function(origin) {
      if (origin === 'all') {
        this.filterCustomers(this.$route.params.filter);
        
        return;
      }

      let token = this.$cookies.get("poolfit_admin_session");

      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token }
        };
        this.$http
          .get(
            process.env.VUE_APP_ROOT_API + "/customers?filter=" + this.$route.params.filter + "&origin=" +origin,
            options
          )
          .then(response => {
            this.search = "";
            this.customers = response.data.customers;
            this.counts = _.head(response.data.meta);
          });
      }
    }
  },
  computed: {
    filteredCustomers() {
      return this.userList.filter(item => {
        let searchPhrase = this.search.toLowerCase();

        return (
          item.fullname.toLowerCase().indexOf(searchPhrase) > -1 ||
          item.email.toLowerCase().indexOf(searchPhrase) > -1
        );
      });
    },

    userList() {
      return this.customers.map(customer => {
        if (
          customer.plan === "plan_E4sZ6jmfULUkc0" ||
          customer.plan === "com.fitmotivation.poolfit.standard.monthly" ||
          customer.plan === "com.fitmotivation.poolfit.subscription.monthly"
        ) {
          customer.plan = "Monthly";
        } else if (
          customer.plan === "plan_E4sYtia9t6lDcv" ||
          customer.plan === "com.fitmotivation.poolfit.standard.annual" ||
          customer.plan === "com.fitmotivation.poolfit.subscription.annual"
        ) {
          customer.plan = "Annual";
        } else {
          customer.plan = "";
        }

        return customer;
      });
    }
  },

  watch: {
    $route(to, from) {
      if (to.fullPath === "/users/filter/active") {
        this.filterCustomers("ACTIVE");
      } else if (to.fullPath === "/users/filter/disabled") {
        this.filterCustomers("DISABLED");
      } else if (to.fullPath === "/users/filter/pending") {
        this.filterCustomers("PENDING");
      } else {
        this.filterCustomers("ALL");
      }
    }
  },

  created: function() {
    this.filterCustomers(this.$route.params.filter);
  }
};
</script>
<style></style>
